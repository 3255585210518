@import '../../ConstantsAndMixins.scss';

.qr-code, .qr-spinner-wrapper {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    margin: 15px;
    box-sizing: border-box;
    background-color: $theme-color-secondary;
    border-radius: $standard-border-radius;

    @include standardBorder;

    canvas {
        border-radius: $standard-border-radius;
    }

    .error-section {
        text-align: center;

        button {
            margin-top: 5px;
        }
    }
}