// colors
$teal: 	#17d2c3;
$white: #ffffff;
$light-gray: rgba(0,0,0,0.2);
$very-light-gray: #d3d3d324;
$red: #e85d5d;

$theme-color-main: $teal;
$theme-color-secondary: $white;

$header-height: 60px;

// borders
$standard-border-radius: 20px;
$standard-border-width: 2px;
$standard-border-color: $light-gray;

@mixin standardBorder() {
    border: solid $standard-border-color $standard-border-width;
    border-radius: $standard-border-radius;
    box-shadow: 0 4px 8px 0 $light-gray;
}

// :export {
//     themeColorLight: $theme-color-light;
//     themeColorDark: $theme-color-dark;
// }