.ended-session-workflow {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    .new-session-button {
        margin-top: 10px;
    }
}