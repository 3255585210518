@import '../../ConstantsAndMixins.scss';

.are-you-sure-modal {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 20px;
    
    @include standardBorder;

    h1 {
        margin: 0;
    }

    .button-section {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }
}