@import '../ConstantsAndMixins.scss';

.header {
    position: fixed;
    z-index: 1;
    background-color: $white;
    height: $header-height;
    width: 100%;
    border-bottom: solid $standard-border-color $standard-border-width;
    box-shadow: 0 4px 8px 0 $light-gray;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;

    .logo-section, .connection-icon {
        display: flex;
        align-items: center;

        svg, img {
            height: 42px;
            width: 42px;
        }
        
        .logo {
            height: 100%;
            margin-right: 10px;
        }

        .logo-url {
            font-family: 'TimeburnerBold';
        }
    }
}