@import '../../ConstantsAndMixins.scss';

.error-toast {
    position: fixed;
    top: $header-height + 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    color: $white;
    padding: 5px 10px;
    border: solid $standard-border-width $white;
    border-radius: $standard-border-radius;
}