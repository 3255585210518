@import './ConstantsAndMixins.scss';

@media screen and (min-width: 992px) {
    .piqr-app {
        width: 640px;
    }
}

/* start of large tablet styles */
@media screen and (max-width: 991px) and (min-width: 480px) {
    .piqr-app {
        width: 480px;
    }
}

/* start of phone styles */
@media screen and (max-width: 479px) {
    .piqr-app {
        width: 320px;
    }
}

@font-face {
    font-family: 'Amazonas-Centered';
    src: local('Amazonas-Centered'), url(./Resources/Fonts/amazonas/amazonas-webfont.woff) format('woff');
}

@font-face {
    font-family: 'Timeburner';
    src: local('Timeburner'), url(./Resources/Fonts/timeburner/timeburnernormal.ttf) format('truetype');
}

@font-face {
    font-family: 'TimeburnerBold';
    src: local('Timeburner'), url(./Resources/Fonts/timeburner/timeburnerbold.ttf) format('truetype');
    font-weight: bold;
}

body {
    background-color: $very-light-gray;
}

.piqr-app {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Amazonas-Centered';
    color: black;
    width: 100%;

    .spinner-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        margin-top: $header-height;
        height: calc(100% - #{$header-height});
        width: 100%;
    }

    button, select {
        font-family: 'Amazonas-Centered';
        font-size: large;
        border-radius: 10px;
        padding: 3px 6px;
    }
}