@import '../ConstantsAndMixins.scss';

$section-header-height: 50px;

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;

    .qr-section, .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .qr-section {
        min-height: calc(100% - #{$section-header-height});
        max-height: calc(100% - #{$section-header-height});
        
        .landing-header {
            text-align: center;
            font-family: 'Timeburner';
            
            .title {
                margin: 5px 0;
                border-bottom: solid 1px black;
                font-family: 'TimeburnerBold';
            }
        }

        .dropdown-label {
            font-size: 18px;
            margin-right: 5px;
        }
    }

    .info {
        .section-header {
            width: 100%;
            height: $section-header-height;
            background-color: black;
            color: white;
            margin-top: 0;
            margin-bottom: 10px; 
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }

        .how-it-works {
            > div {
                padding: 5px 20px;

                .step-number {
                    font-weight: bold;
                    margin-right: 10px;
                    width: 10px;
                    display: inline-flex;
                    justify-content: center;
                }
            }
        }

        .blurb {
            padding: 5px 20px;
            margin: 15px 20px;
            text-align: center;
            
            @include standardBorder;
        }
    }
}