@import '../ConstantsAndMixins.scss';

$header-height: 60px;
$overlay-padding: 10px;
$img-margin: 5px;

.gallery {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;

    .gallery-header, .gallery-footer {
        height: $header-height;
        width: 100%;
        position: absolute;
        text-align: center;
        pointer-events: none;
    }

    .gallery-header {
        top: 0;
        background: linear-gradient(to bottom, white, rgba(255,255,255,0));
    }

    .gallery-footer {
        bottom: 0;
        background: linear-gradient(to top, white, rgba(255,255,255,0));
    }

    .photo-count, .qr-icon, .footer-message {
        background-color: rgba(0, 0, 0, 0.5);
        color: $white;
        border-radius: 10px;
        padding: 10px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: $overlay-padding;
        width: 100%;
        z-index: 1;

        .polaroid, .qr-icon {
            height: 30px;
            width: 30px;
        }

        .photo-count {
            display: flex;
            align-items: center;
            font-size: large;
            margin-left: $overlay-padding;

            .polaroid {
                margin-right: 8px;
            }
        }

        .qr-icon {
            margin-right: $overlay-padding;

            &.not-active {
                background-color: $red;
            }
        }
    }

    .footer-message {
        position: absolute;
        bottom: $header-height;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        padding: 5px 10px;
        pointer-events: none;

        &.not-active {
            background-color: $red;
        }
    }

    .button-section {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .gallery-pics {
        // display: flex; this breaks scrolling
        padding: $header-height 0;
        overflow: auto;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        scroll-snap-type: y mandatory;

        .loading-img {
            height: 100%;
            width: 300px;
            margin: 0 auto;
            border-radius: $standard-border-radius;
            background-color: rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                height: 100px;
            }
        }

        .img-container {
            // display: flex; this is weird on Safari iOS- scrunches the photos
            height: 100%;
            box-sizing: border-box;
            padding: 5px;
            margin: $img-margin;
            scroll-snap-align: center;
            position: relative;

            .preview-img {
                max-width: calc(100% - #{2*$img-margin});
                max-height: calc(100% - #{2*$img-margin});
                border-radius: $standard-border-radius;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .connection-made-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                height: 100px;
                width: 100px;
                margin-bottom: 10px;
            }

            label {
                font-size: large;
            }
        }
    }
}