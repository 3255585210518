@import '../../ConstantsAndMixins.scss';

.camera-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .open-camera-button, .loading-area, .sent-area {
        width: 290px;
        height: 290px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include standardBorder;

        img, svg {
            height: 80px;
        }

        .button-label {
            font-size: large;
            margin-top: 5px;
            color: black;
        }
    }

    .open-camera-button {
        border-style: outset;
        border-width: 5px;
    }
    
    .camera-input {
        display: none;
    }

    .button-section {
        display: flex;

        .end-session-button {
            margin-right: 5px;
        }
    }
}